
.package-box{
    padding: 24px;
    margin: 0 24px;
    border: solid 1px silver;
    border-radius: 15px;
    text-align: center;
}

.features {
    text-align: left;
}