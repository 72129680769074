
.plan-box {
    border: solid 1px silver;
    border-radius: 5%;
    margin: 12px;
    padding: 12px 48px;
    box-shadow: 0 0 5px 0 rgba(0,0,0,0.1);
    text-align: center;
}

.active-plan {
    background:white;
    border: solid 2px silver;
}

.plan-features {
    padding: 12px;
    border-top: solid 1px silver;
    margin-top: 12px;
    text-align: left;
}

.plan-box li {
    font-size: 14px;
}