
.profile-logo img {
    max-width: 300px;
    max-height: 300px;
}

.profile-header {
    width: 100%;
    height: 300px;
    overflow: hidden;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}