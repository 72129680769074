.admin-answer {
    text-align: right;
}

.message {
    background: white;
    padding: 12px;
    border: solid 1px silver;
    border-radius: 15px;
    font-size: 18px;
}