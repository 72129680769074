body {

}

code {

}

.content {
    padding: 5%;
    min-height: 300px;
}

.bed {
    border: solid 1px rgb(69, 34, 13);
    margin-bottom: 1rem;
}

.planting {
    position: absolute;
    overflow: hidden;
    border: solid 1px silver;
    text-align: center;
    background-position: center;
    cursor: pointer;
    background-size: cover;
}

.planting span {
    padding: 12px 0;
    display: block;
    background: rgba(255, 255, 255, 0.5);
}

.bed-wrapper h1 {
    margin-top: 16px;
}


.planting-preview {
    animation: blinker 1s linear infinite;
    border: dashed 4px green;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}
.text-right {
    text-align: right;
}
.menu-item-logout {
    margin-left: auto;
}

.mar-b-1 {
    margin-bottom: 1rem;
}
.mar-t-1 {
    margin-top: 1rem;
}
.mar-t-2 {
    margin-top: 2rem;
}

/* PERIOD WRAPPER STUFF */

.periods-wrapper{
    display: flex;
    width: 100%;
}

.period-block{
    border-radius: 3px;
    flex-grow: 1;  /* Makes the children expand equally */
    text-align: center;  /* Optional: Centers content in the children */
    border: 1px solid silver; /* Optional: Add a border for visual clarity */
    line-height:24px;
    margin: 12px 2px;
    font-size:8px;
}

.period-in-seed {
    background: #5dbc5d;
    color: white;
}
.period-in-bed {
    background: #5d5dc3;
    color: white;
}
.period-in-harvest {
    background: #c35d5d;
    color: white;
}