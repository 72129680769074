.update-box {
    text-align: center;
    border: solid 1px silver;
    padding: 12px;
    height: calc(100% - 12px);
    margin: 12px 0;
}

.update-box h1 {
    margin: 12px 0;
}

.update-image-box {
    height: 100px;
    width:100%;
    overflow: hidden;
}