h1, th, h2 , h3{
    font-family: "PT Serif", serif;
    color: #141B22;
}

h1{
    font-size: 28px;
}

.custom-slider{
    background-color: #F9F4E8 !important;
    border-right: solid 1px rgba(9, 54, 162, 0.1);
}

.custom-slider .ant-layout-sider-trigger {
    background-color: #F9F4E8;
}
.custom-slider .ant-layout-sider-trigger span {
    color: #141B22;
}


.custom-slider .ant-menu-root{
    border: none !important;
}

#planting-preview svg {
    width: 100%
}