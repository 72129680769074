.bed-container {
    position: relative;
    width: 100%;
    height: calc(100vh - 200px);
    background: brown;
    overflow:auto;
}

.bed {
    position: absolute;
    background-color: #3498db;
    cursor: pointer;
    transform-origin: center;
    color: white;
}
.bed-controls {
    padding: 10px;
}