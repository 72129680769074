
.garden-box{
    border:solid 1px silver;
    border-radius: 5px;
    padding: 18px;
    margin-bottom: 18px;
}

.todo-wrap{
    margin-bottom: 8px;
}
.todo-text {
    margin-left: 8px;
}