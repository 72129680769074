
.crop-image {
}
.crop-row {
    border-bottom: solid 1px silver;
    padding: 12px 0;
}
.crop-row img, .search-result img  {
    max-height: 100%;
    max-width: 80%;
}
.search-result {
    padding-bottom: 8px;
    margin-top: 8px;
}

.ant-input-number{
    width: 100% !important;
}

.mar-r-1{
    margin-right: 1rem;
}
.mar-l-1{
    margin-left: 1rem;
}
.pad-1{
    padding: 1rem;
}